<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Spécialité médicale" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail</div>
              </v-row>
            </v-card-title>

            <!-- Le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field 
                    dense 
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="name"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La dénomination commerciale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">dénomination commerciale</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="commercialName"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La nomenclature -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nomenclature</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="nomenclature"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Formulaire externe -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    Formulaire externe report chirurgies
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="cbForms"
                    v-model="form"
                    no-data-text="aucune donnée"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La fonction associée -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">Nom digital du spécialiste</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="functions"
                    v-model="functionId"
                    item-text="name"
                    item-value="id"
                    no-data-text="aucune fonction"
                    :search-input.sync="watchFunction"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- Le nom de la profession -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    Nom profession
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="professionName"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Le slug de la profession -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    Slug profession
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="slugProfession"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La profession médicale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    Profession médicale
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="professions"
                    v-model="selectedProfession"
                    no-data-text="aucune profession"
                    item-text="label"
                    item-value="codeMos"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- Alert suppression profession name et slug -->
    <StandardDialogConfirmed
    title="Confirmez votre action"
    label= "Le changement de nom digital de spécialiste va modifier les champs 'Nom profession' et 'Slug profession'."
    :labelHtml="false"
    :visible.sync="showStandardDialog"
    :item="itemToDelete"
    @confirmed="initProfessionAndSlug()" >
    </StandardDialogConfirmed>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";
import StandardDialogConfirmed from "@/components/ui/StandardDialogConfirmed.vue";
import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import { FonctionService } from "@/service/sfr/function_service.js";
import { MedicalProfessionService } from "@/service/practitioners/medical_profession_service.js";

import { areTheSame, trimString } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditMedicalSpecialty",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
    StandardDialogConfirmed,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès à l'api */
      serviceMedicalSpecialties: null,
      serviceMedicalProfession: null,

      /** l'identifiant à éditer */
      itemId: null,

      /**La donnée originale */
      source: null,

      serviceFunction: null,

      /**le nom digital */
      digitalName: null,
      /**le nom de la spécialité */
      name: null,
      /**la dénomination commerciale */
      commercialName: null,
      nomenclature: null,
      externalSurgicalDeferralForm: null,

      functions: [],

      functionId: null,
      watchFunction: '',

      cbForms: [],

      form: null,

      /** La liste des professions */
      professions: [],

      selectedProfession: null,
      professionName: null,
      slugProfession: null,

      /** L'état de visibilité de la popup */
      showStandardDialog: false,
      itemToDelete: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        let item = await this.serviceMedicalSpecialties.getById(this.itemId);
        this.source = JSON.parse(JSON.stringify(item));

        // Récupère l'ensemble des professions
        this.professions = await this.serviceMedicalProfession.getAll();

        if (item.medicalProfessionId) {
          this.selectedProfession = this.professions.find((p) => p.codeMos == item.medicalProfessionId);
        }

        //chargement des fonctions pour jointure
        this.functions = await this.serviceFunction.getAll();

        this.cbForms.push(
          { text: "oui", value: true },
          { text: "non", value: false }
        );

        this.form = this.cbForms.find(
          (form) => form.value === item.externalSurgicalDeferralForm
        );

        this.init();

        // console.log(JSON.stringify(item, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    init() {
      this.digitalName = this.source.digitalName;
      this.name = this.source.name;
      this.commercialName = this.source.commercialName;
      this.functionId = this.source.functionId;
      this.nomenclature = this.source.nomenclature;
      this.externalSurgicalDeferralForm = this.source.externalSurgicalDeferralForm;

      this.professionName = this.source.professionName;
      this.slugProfession = this.source.slugProfession;
    },
    async save() {
      try {
        let element = JSON.parse(JSON.stringify(this.source));

        element.digitalName = this.digitalName;
        element.name = this.name;
        element.commercialName = this.commercialName;
        element.functionId = this.functionId;
        element.nomenclature = this.nomenclature;
        element.externalSurgicalDeferralForm = this.form.value;

        element.medicalProfessionId = this.selectedProfession.codeMos;
        element.professionName = this.professionName;
        element.slugProfession = this.slugProfession;


        await this.serviceMedicalSpecialties.update(element);

        this.source = JSON.parse(JSON.stringify(element));

        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    /** Initialise les champs de nom de profession et slug profession en fonction de la fonction sélectionnée */
    initProfessionAndSlug() {
      
      if (this.itemToDelete) {
        // Pré-saisie le nom de la fonction
        this.professionName = this.itemToDelete.definition;
        this.slugProfession = this.convertToSlug(this.itemToDelete.definition);
      }

      this.itemToDelete = null;
      this.showStandardDialog = false;
    },

    /** Pré-saisie le nom de la profession et le slug de la profession en fonction de la fonction sélectionnée  */
    selectFunction(value) {
      // Si la valeur est valide
      if (value) {
        // Recherche la fonction correspondante à la sélection
        let found = this.functions.find((f) => f.name == value);

        // Si l'on trouve la fonction
        if (found) {
          // En cas de retour à la normale
          if (found.id == this.source.functionId) {
            this.professionName = this.source.professionName;
            this.slugProfession = this.source.slugProfession;
          } else {

            this.itemToDelete = found;

            // Si pas de profession --> on pré-remplie les champs
            if (!this.professionName) {
              this.initProfessionAndSlug();
            } else if (this.source.functionId != found.id) {
              // Si profession --> on affiche la popup de confirmation de changement de ces champs
              this.showStandardDialog = true;
            }
          }
        }
      }
    },    

    /** Convertion d'une chaine en slug profession
     * Règles : 
     *          - pas de : 'en' 'de' 'la' 'du' 'et' 'des'
     *          - texte en minuscule
     *          - pas d'accents
     *          - un tiret pour chaque espaces
     */
    convertToSlug(value) {
      const regex = /(^| )en($| )|(^| )de($| )|(^| )la($| )(^| )du($| )(^| )et($| )(^| )des($| )/ig;

      // Mise en minuscule
      let result = value.toLowerCase();
      // Suppression des accents
      result = result.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      // Remplacement des mots du regex par un espace
      result = result.replaceAll(regex, ' ');
      // Remplacement des espaces par des tirets
      result = result.replaceAll(' ', '-');

      return result;
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },

  },
  computed: {
    completed() {
      if (!this.digitalName) return false;
      if (!this.functionId) return false;
      if (!this.selectedProfession) return false;
      if (!this.professionName) return false;
      if (!this.slugProfession) return false;

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (!areTheSame(this.source.digitalName, trimString(this.digitalName))) {
        changed = true;
      }

      if (!areTheSame(this.source.name, this.name)) {
        changed = true;
      }

      if (!areTheSame(this.source.nomenclature, this.nomenclature)) {
        changed = true;
      }

      if (!areTheSame(this.source.commercialName, this.commercialName)) {
        changed = true;
      }

      if (this.source.externalSurgicalDeferralForm !== this.form.value) {
        changed = true;
      }

      if (this.source.functionId !== this.functionId) {
        changed = true;
      }

      if (this.source.medicalProfessionId != this.selectedProfession.codeMos) {
        changed = true;
      }
      if (!areTheSame(this.source.professionName, this.professionName)) {
        changed = true;
      }
      if (!areTheSame(this.source.slugProfession, this.slugProfession)) {
        changed = true;
      }

      return changed;
    },
  },

  watch: {
    /** Surveille la sélection d'une function */
    watchFunction(value) {
      this.selectFunction(value);     
    }
  },

  mounted() {
    this.serviceMedicalSpecialties = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi()
    );

    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());
    this.serviceMedicalProfession = new MedicalProfessionService(this.$api.getMedicalProfessionApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.itemId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>